import React from "react"
import Hero from "../components/Hero"
import HeroLead from "../components/HeroLead"
import HeroTitle from "../components/HeroTitle"
import Layout from "../components/Layout"

export default (): JSX.Element => (
  <Layout>
    <Hero hideContact>
      <HeroTitle>
        <h1>Sivua ei löydy :(</h1>
      </HeroTitle>
      <HeroLead>Pahoittelemme, mutta emme löytäneet sivua.</HeroLead>
    </Hero>
  </Layout>
)
